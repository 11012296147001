<template>
  <div>
    <Section1 :keyWord="'La mejor solución para empeñar tu vehículo en Sevilla'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>La mejor solución para empeñar tu coche en Sevilla</strong></h2>
      <p>¿Vives en Sevilla y necesitas dinero urgente?, ¿No tienes tiempo ni ganas de esperar a que te conceda un crédito el banco? Estás de enhorabuena. Con el servicio de <strong>empeños de coches en Sevilla</strong> de Ibancar tendrás toda la liquidez que necesitas en el mismo día.</p>
      <p>¿Sabías que con Ibancar se tramita todo el proceso de <strong>empeños de coches en Sevilla</strong> de manera 100% online y sin necesidad de salir de casa? Siendo los únicos en no cambiar la titularidad. Te explicamos todos los detalles.</p>

      <h3 class="naranja"><strong>Empeñar coche en Sevilla</strong></h3>
      <p>Hay algunas entidades que te obligan a dejar tu coche en un depósito hasta que satisfaces la deuda, o a cambiar la titularidad del vehículo, o incluso a cobrarte un renting o un alquiler para que puedas seguir conduciendo.</p>
      <p>Olvídate de todo eso. Ibancar tiene el programa de <strong>empeños de coches en Sevilla</strong> más flexible del mercado. ¿Sabías que está disponible también para personas que han sido incluidas en ficheros de impagados como los de la Asnef?</p>

      <h3 class="naranja"><strong>Empeña tu coche en Sevilla y sigue usándolo</strong></h3>
      <p>Con Ibancar y su sistema de <strong>empeños de coches en Sevilla</strong> no vas a perder ni un solo privilegio en tu vehículo. Además de poder seguir conduciendo sin ningún tipo de restricción, no se te pedirá que dejes una copia de la llave ni que entregues la documentación original.</p>
      <p>Tu coche nunca acabará aparcado en un depósito con el programa de <strong>empeños de coches en Sevilla</strong> de Ibancar, y no tendrás que pagar ni un solo euro para disfrutarlo.</p>

      <h3 class="naranja"><strong>¿Cómo empeñar tu coche en Sevilla con Ibancar?</strong></h3>
      <p>Vamos a ver cómo puedes aprovecharte del mejor servicio de empeños de coches en sevilla del mercado:</p>
      <ol>
          <li>Rellena el formulario de la web de Ibancar con la cantidad que necesitas.</li>
          <li>En unos minutos recibirás una propuesta de crédito personalizada.</li>
          <li>Envía la documentación necesaria (Este paso es fundamental. Si lo realizas con agilidad nuestros agentes podrán verificar la información y activar el préstamo en un tiempo récord).</li>
          <li>En un plazo de 24 horas máximo podrás empezar a disfrutar del dinero en tu cuenta.</li>
      </ol>

      <h3 class="naranja"><strong>Ventajas de empeñar tu coche en Ibancar</strong></h3>
      <p>Esta filosofía implica numerosas ventajas para todos los usuarios del servicio.</p>
      <ul>
          <li>Se te asigna un gestor personal para ayudarte en todo lo que necesites.</li>
          <li>El préstamo se amortiza desde la primera cuota.</li>
          <li>Se garantiza la amortización del crédito por siniestro total.</li>
          <li>Tienes derecho a una revisión técnica anual de mantenimiento gratuita.</li>
          <li>¿Necesitas ampliar el importe inicial del préstamo? Estudiamos las opciones.</li>
          <li>Diferentes formas de pago.</li>
          <li>Puedes elegir el día para realizar el pago.</li>
          <li>Dispones de 5 días de cortesía para abonar el importe de cada pago.</li>
          <li>Incluimos un servicio de compra del vehículo si finalmente no puedes hacer frente a los pagos.</li>
      </ul>


      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h4 class="title-h1 text-center">Préstamo por tu coche</h4>
        </div>
      </div>
<!-- fila 1  -->
      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4">
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamo por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../empenar-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">La mejor solución al empeño de Coches. Préstamos rápidos con tu coche sin dejar de usarlo</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Dinero rápido con tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      <!-- fila 2 -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
      </div> 
<!-- fila landing 3  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-sevilla" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche en &nbsp; Sevilla</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Credito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito aval coche con Asnef</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito con aval</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCocheSevilla',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Empeña tu coche en Sevilla ▷ 100% online, sin entregar vehículo y sin cambio de titular',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Obtén hasta 6.000 euros en 24 horas empeñando tu coche en Sevilla con Ibancar, realiza todo el proceso 100% online.'},
        {name:'keywords', content:'empeño de coches sevilla'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-between(xl,xxl) {
  h3 {
    font-size: 1.25rem;
  }
}
</style>