<template>
  <div>
    <Section1 :keyWord="'Alternativa fácil y segura a empeñar coche en Granada'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Alternativa fácil y segura a empeñar coche en Granada</strong></h2>
      <p>¿Necesitas obtener liquidez de forma urgente en Granada? ¿Te has encontrado con  dificultades para acceder a préstamos en entidades bancarias debido a estar en Registro de Aceptaciones Impagadas (RAI)  o en la Asociación Nacional de Establecimientos Financieros de Crédito (ASNEF)? </p>
      <p>Puede que hayas valorado la opción de <strong>empeñar tu coche en Granada</strong>, pero ¡No te precipites! Te presentamos una solución eficiente y ágil que te permitirá obtener la liquidez que necesitas en tiempo récord, sin tener que dejar de utilizar tu coche, y con un proceso completamente online. ¿Quieres saber más? A continuación te presentamos el servicio de Ibancar: la alternativa al tradicional empeño de coches.</p>

      <h3 class="naranja"><strong>Alternativa a empeñar coche en Granada</strong></h3>
      <p>¿Sabías que existe una alternativa al empeño en la que tu coche actúa como aval para conseguir un crédito? Te la ofrece Ibancar con unas condiciones mucho más flexibles que las de las entidades de crédito tradicionales.</p>
      <p>Cada vez son más las personas que escogen el servicio que ofrece Ibancar. Este a diferencia del tradicional <strong>empeño de coches Granada</strong>, es un servicio que te permite conseguir dinero de forma rápida, para poder solventar problemas económicos puntuales, usando tu coche como aval. </p>
      <p>¿Qué lo hace diferente a los otros servicios? A diferencia de empresas de empeño o entidades bancarias que conceden servicios similares, Ibancar no impone condiciones abusivas tales como: el cambio de titularidad del vehículo, su traslado a un depósito hasta que se satisface la deuda o incluso el renting o alquiler al propietario para que siga utilizándolo para ir a trabajar o para llevar a sus hijos al colegio.</p>
      <p>A continuación te presentamos otras ventajas del crédito con aval de coche de Ibancar.</p>

      <h3 class="naranja"><strong>La alternativa a empeñar tu coche en Granada, te permite seguir usándolo </strong></h3>
      <p>Cómo hemos comentado anteriormente, podrás seguir usando tu coche sin ningún tipo de restricción, debido a que sólo actúa como aval testimonial. Lo único que pedirá Ibancar, es que a lo largo del trámite no estará permitida la venta del vehículo.</p>
      <p>Al ser un servicio online, puedes conseguir hasta 6.000€ hoy, sin salir de casa. Además, contarás con un gestor asociado durante todo el proceso, es decir, hablarás siempre con la misma persona, sin tener que contar lo mismo a varias personas y con soluciones inmediatas. </p>
      <p>Si aquello que te preocupa es aparecer en el Registro de Aceptaciones Impagadas (RAI)  o en la Asociación Nacional de Establecimientos Financieros de Crédito (ASNEF), desde Ibancar queremos decirte que para nosotros no es un problema. </p>
      <p>Estas y muchas más, son las ventajas que te ofrece el servicio del préstamo personal con coche como aval de Ibancar.  ¿Te interesa la alternativa a <strong>empeñar coche Granada</strong> de Ibancar? A continuación te explicamos cómo tramitarlo paso a paso a continuación.</p>

      <h3 class="naranja"><strong>¿Cómo solicitar la alternativa a empeñar coche en Granada?</strong></h3>
      <p>El proceso se completa de manera 100% online en cuestión de minutos. Tendrás a un agente personal para ayudarte en todo lo que necesites y puedes tener el dinero en tu cuenta en un par de horas si aportas con agilidad la documentación requerida.</p>
      <ul>
        <li>Rellena el formulario de solicitud. Completa todos los campos con la información requerida, incluyendo la cantidad de dinero que necesitas y los datos básicos sobre tu coche.</li>
        <li>Una vez que hayas enviado el formulario de solicitud, el equipo de Ibancar te devolverá una propuesta de crédito pre concedida a tu nombre.</li>
        <li>Acepta la propuesta y envía la documentación adicional requerida para formalizar el contrato.</li>
        <li>Una vez firmado el contrato, Ibancar transferirá el dinero a la cuenta bancaria que hayas indicado. En tan solo unas horas podrás disponer de liquidez para cubrir cualquier necesidad</li>
      </ul>

      <h3 class="naranja"><strong>Documentación necesaria para acceder al préstamo personal de Ibancar</strong></h3>
      <p>Antes de proceder a solicitar el préstamo con aval de coche de Ibancar, debes saber que documentación básica deberás de presentar. </p>
      <p>Como titular del vehículo:</p>
      <ul>
        <li>Fotografía del DNI – ambas caras -, sobre un fondo blanco.</li>
        <li>Última nómina, pensión o certificado de autónomo</li>
        <li>Fotografía estilo selfie junto con el DNI para probar la identidad del demandante.</li>
      </ul>
      <p>Con respecto al vehículo, se deberá presentar:</p>
      <ul>
        <li>Permiso de circulación y ficha técnica.</li>
        <li>Fotografía del cuentakilómetros con el DNI del conductor.</li>
        <li>Material audiovisual: cinco fotografías y un vídeo en perfecta calidad, de ambos lados, parte anterior y posterior del vehículo. Para poder apreciar su estado.</li>
      </ul>

      <h3 class="naranja"><strong>Ventajas del crédito con aval de coche en Granada</strong></h3>
      <ul>
        <li>Ibancar ofrece un proceso rápido y eficiente, enviando el dinero a tu cuenta en menos de dos horas, con un récord de una hora en algunas ocasiones.</li>
        <li>La solicitud del préstamo se realiza de manera completamente online, evitando trámites presenciales y papeleos. </li>
        <li>Muchos menos requisitos en comparación con los bancos tradicionales, facilitando el acceso al préstamo incluso a personas incluidas en ficheros de impagados.</li>
        <li>Ibancar ofrece la opción de ampliar el préstamo si se necesita más dinero y también permite periodos de carencia para aquellos momentos en los que se requiere un respiro financiero.</li>
        <li>Ibancar garantiza el buen estado del coche durante todo el tiempo del préstamo, gracias a la revisión anual mecánica realizada por Midas.</li>
      </ul>
      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h4 class="title-h1 text-center">Préstamo por tu coche</h4>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamo por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Dinero rápido con tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      <!--  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
              <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
                <div class="card-body">
                  <h3 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h3>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
                </div>
              </a>
          </div>
      </div> 

<!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
      </div>

<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito aval coche con Asnef</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito con aval</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCocheGranada',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Empeñar Coche de forma fácil, segura en Granada | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Obtén hasta 6.000 euros en 24 horas empeñando tu coche en Granada con Ibancar, realiza todo el proceso 100% online.'},
        {name:'keywords', content:'empeño de coches granada'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-between(xl,xxl) {
  h3 {
    font-size: 1.25rem;
  }
}
</style>