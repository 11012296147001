<template>
  <div>
    <Section1 :keyWord="'La mejor solución para empeñar tu vehículo en Murcia'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Empeñar coche en Murcia</strong></h2>
      <p>¿Vives en Murcia o en sus alrededores y necesitas liquidez urgente en tu cuenta?, ¿Sabías que tienes acceso a un préstamo de hasta 6000 euros por <strong>empeñar coche en Murcia</strong> sin renunciar a seguir disfrutándolo sin restricciones?</p>
      <p>Estás a tan solo unos minutos de conseguir todo el dinero que necesitas por <strong>empeñar coche Murcia</strong>. Con Ibancar puedes tener el dinero en tu cuenta en menos de 24 horas completando todo el proceso de manera 100% online.</p>
      <h3 class="naranja"><strong>¿Cómo puedo empeñar coche Murcia sin que acabe mi vehículo en un depósito?</strong></h3>
      <p>Debes tener cuidado a la hora de <strong>empeñar coche Murcia</strong>, ya que algunas empresas de crédito te conceden el dinero a cambio de que tu vehículo se quede en un depósito hasta que satisfagas la deuda.</p>
      <p>Para que no te lleves ninguna sorpresa desagradable a la hora de <strong>empeñar coche Murcia</strong>, a continuación descubrirás cómo <strong>empeñar coche Murcia</strong> a través del crédito con aval de coche de Ibancar sin perder ni un solo derecho sobre tu automóvil. El coche siempre será tuyo  y sólo funciona como garantía para el préstamo.</p>

      <h3 class="naranja"><strong>Cómo empeñar coche Murcia, seguir conduciendo y recibir el dinero en 24 horas</strong></h3>
      <p><strong>Empeñar coche Murcia</strong> y contar con liquidez en tu cuenta bancaria en menos de 24 horas no depende solo de Ibancar. Cuanto más rápido actúes y antes completes los pasos a seguir, antes verás el dinero en tu cuenta.</p>
      <p>Lo primero que tienes que hacer para <strong>empeñar coche Murcia</strong> es rellenar el sencillo formulario de la web de Ibancar incluyendo la cantidad de dinero que necesitas y la matrícula del coche que servirá como garantía.</p>
      <p>Una vez completado este primer paso para <strong>empeñar coche Murcia</strong> recibirás de forma inmediata cuánto dinero te podemos prestar. </p>
      <p>El siguiente paso para <strong>empeñar coche Murcia</strong> es muy sencillo: Enviar la documentación requerida. De tu velocidad dependerá el tiempo que tardes en ver el dinero en tu cuenta. Si resuelves con agilidad podrías reducir el tiempo del ingreso en bastante menos de 24 horas, ya que nuestro equipo revisa tu documentación al instante y siempre tendrás un agente para ayudarte con cualquier duda que tengas.</p>
      <p> Una vez la documentación enviada revisaremos que está correcta para proceder a la firma digital. El contrato será enviado vía email y te llamaremos para explicarte al detalle lo que necesites y así saber exactamente lo que estás firmando. ¡Claro y transparente!</p>

      <h3 class="naranja"><strong>¿Qué pasa con el vehículo al empeñar coche Murcia con Ibancar?</strong></h3>
      <p>A diferencia de lo que ocurre con otras entidades que ofrecen dinero por empeñar coche Murcia, en Ibancar vas a seguir disfrutando de tu vehículo sin ningún tipo de restricción. No se te pedirá una copia de la llave ni la documentación original del vehículo, no habrá cambios de titularidad y no tendrás que entregarlo para dejarlo en un depósito.</p>
      <p>Además, al convertirte en cliente de Ibancar tendrás derecho a servicios exclusivos como una revisión anual de mantenimiento, días de cortesía, meses de carencia, cancelación parcial y total, y un agente durante la duración del préstamo entre otros beneficios.</p>
      <p>¿A qué esperas? Consigue dinero hoy, sin venderlo y sin empeñarlo.</p>

      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
        <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>
<!-- fila 1  -->
      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4">
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamo por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../empenar-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">La mejor solución al empeño de Coches. Préstamos rápidos con tu coche sin dejar de usarlo</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Dinero rápido con tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      
      
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
      </div> 
<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito aval coche con Asnef</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito con aval</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCocheMurcia',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'La mejor solución para empeñar tu vehículo en Murcia | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'¿Estás viviendo en Murcia y necesitas dinero urgente en tu cuenta? Estás de enhorabuena. Gracias al servicio  “empeña tu coche Murcia” de Ibancar podrás contar con liquidez en tu cuenta en menos de 24 horas y completar todo el proceso de manera 100% online.'},
        {name:'keywords', content:'empeña tu coche murcia'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-between(xl,xxl) {
  h3 {
    font-size: 1.25rem;
  }
}
</style>