<template>
  <div>
    <Section1 :keyWord="'Alternativa fácil y segura a empeñar coche en Valencia'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Alternativa fácil y segura a empeñar coche en Valencia</strong></h2>
      <p>¿Estás viviendo en Valencia y necesitas dinero urgente? Desde hace 8 años, en Ibancar te ofrecemos la solución más rápida y efectiva. Usando tu coche como garantía de préstamo, recibirás en un plazo máximo de 24 horas la liquidez que necesitas. ¡Así de práctica y rápida es, la alternativa al empeño de coche en Valencia, que te ofrece Ibancar!</p>
      <p>Con el servicio de Ibancar, no tienes que cambiar el titular de tu vehículo, podrás completar el trámite de manera 100% online y no importará si te han incluido en algún fichero de impagados como el de la ASNEF. </p>
      <h3 class="naranja"><strong>Alternativa a empeñar coche en Valencia</strong></h3>
      <p>En la actualidad cuando una persona necesita liquidez recorre a entidades de crédito o servicios de empeño. Cada vez son más las que escogen el servicio que ofrece Ibancar. Este a diferencia del tradicional <strong>empeño de coches</strong>, es un servicio que te permite conseguir dinero de forma rápida, para poder solventar problemas económicos puntuales, usando tu coche como aval. </p>
      <p>En Ibancar valoramos mucho el tiempo de nuestros clientes y buscamos ofrecerle las mejores opciones y oportunidades para que puedan solucionar cualquier situación que les preocupe.</p>
      <p>Hay empresas dedicadas a <strong>empeñar coches en Valencia</strong> que señalan como requisito el cambio de titularidad del vehículo o bien cuotas por el uso de este. En cambio, la alternativa que te ofrece Ibancar con el préstamo personal con el coche como aval, el vehículo sólo será una garantía para el préstamo.</p>
      <p>Es por esta razón que el conductor no deberá de abonar ninguna cuota de renting y además, podrá usar el vehículo a lo largo del trámite. La única obligatoriedad que se impone con este método es que no se puede vender el coche mientras tengas el préstamo activo.</p>

      <h3 class="naranja"><strong>La alternativa a empeñar tu coche en Valencia, te permite seguir usándolo </strong></h3>
      <p>Además de todas las ventajas que te hemos comentado, lo cual te garantizará que podrás seguir disfrutando de manera fácil y segura de tu vehículo, el servicio de préstamos personales de Ibancar tampoco exige la entrega de una copia de las llaves ni de la documentación original del vehículo.</p>
      <p>Al ser un proceso online y sin demasiados trámites, cuando te decidas a solicitar el servicio alternativo a <strong>empeñar tu coche Valencia</strong>, podrás obtener tu dinero en 24 horas, amortizando el préstamo desde la primera cuota.</p>
      <p>¿Te preocupa aparecer en Registro de Aceptaciones Impagadas (RAI)  o en la Asociación Nacional de Establecimientos Financieros de Crédito (ASNEF)? ¡Para Ibancar no será un problema! Aunque el cliente figure en el RAI o ASNEF, le podremos ayudar. Esta es otra de las ventajas que te ofrece el servicio del préstamo personal con coche como aval de Ibancar. </p>
      <p>Al ser un servicio online, puedes conseguir hasta 6.000€ hoy, sin salir de casa. Además, contarás con un gestor asociado durante todo el proceso, es decir, hablarás siempre con la misma persona, sin tener que contar lo mismo a varias personas y con soluciones inmediatas. </p>
      
      <h3 class="naranja"><strong>¿Cómo solicitar la alternativa a empeñar coche en Valencia?</strong></h3>
      <p>¿Has decidido ya usar tu coche como aval con Ibancar? ¡Excelente decisión! Vamos a explicarte cómo realizar la solicitud para poder recibir el dinero de la manera más rápida:</p>
      <ol>
          <li>Rellena el formulario de Ibancar con tus datos y la cantidad de dinero que necesitas.</li>
          <li>Una vez terminado recibirás una propuesta de préstamo preaprobado.</li>
          <li>Envía la documentación adicional necesaria para formalizar el préstamo.</li>
          <li>Nuestro equipo de profesionales verificará los documentos a la mayor brevedad.</li>
          <li>Disfruta del dinero en tu cuenta en el mismo día.</li>
      </ol>

      <h3 class="naranja"><strong>Documentación necesaria para acceder al préstamo personal de Ibancar</strong></h3>
      <p>Antes de proceder a solicitar el préstamo con aval de coche de Ibancar, debes saber que documentación básica deberás de presentar. </p>
      <p>Como titular del vehículo:</p>
      <ul>
        <li>Fotografía del DNI – ambas caras -, sobre un fondo blanco.</li>
        <li>Última nómina, pensión o certificado de autónomo</li>
        <li>Fotografía estilo selfie junto con el DNI para probar la identidad del demandante.</li>
      </ul>
      <p>Con respecto al vehículo, se deberá presentar:</p>
      <ul>
        <li>Permiso de circulación y ficha técnica.</li>
        <li>Fotografía del cuentakilómetros con el DNI del conductor.</li>
        <li>Material audiovisual: cinco fotografías y un vídeo en perfecta calidad, de ambos lados, parte anterior y posterior del vehículo. Para poder apreciar su estado.</li>
      </ul>
      <h3 class="naranja"><strong>Ventajas de escoger el servicio alternativo al empeño de coche que ofrece Ibancar</strong></h3>
      <p>¿Sabías que la alternativa a <strong>empeñar coche en Valencia</strong> a través del préstamo con aval de coche de Ibancar tienes derecho a servicios exclusivos para clientes?</p>
      <p>Una de las mayores ventajas es que,en Ibancar no tenemos en cuenta que hayas sido incluido en un fichero de impagados. Un justificante de ingresos será suficiente para conceder el crédito.</p>
      <p>Además, vas a contar con un asesor personal que te asistirá durante todo el proceso y un espacio en nuestro sitio web de clientes desde el que vas a poder consultar toda la información sobre el préstamo.</p>
      <p>Entre los servicios exclusivos para clientes de Ibancar se encuentran:</p>
      <ul>
        <li>5 días de cortesía para efectuar los pagos.</li>
        <li>Fácil cancelación del préstamo para ahorrarse los intereses.</li>
        <li>Disponibilidad de meses de carencia.</li>
        <li>Elección de la fecha mensual de pago que más te convenza.</li>
        <li>Revisión técnica anual de mantenimiento.</li>
        <li>Garantía de amortización por siniestro total.</li>
      </ul>
      <p>Nunca solicitar un préstamo con aval de coche había sido tan sencillo como con Ibancar. No lo dudes y pide tu información sin compromiso alguno. ¡Ibancar te ofrece la alternativa rápida, ágil y plenamente segura al <strong>empeño de tu coche en Valencia!</strong></p>



      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h4 class="title-h1 text-center">Préstamo por tu coche</h4>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamo por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Dinero rápido con tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      <!--  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-valencia" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche en Valencia</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
      </div> 

<!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-por-tu-coche-alicante" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos por tu coche Alicante</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-aval-coche-valencia" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos aval coche &nbsp; Valencia</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
    
      </div>

<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito aval coche con Asnef</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito con aval</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCocheValencia',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Empeñar Coche de forma fácil, segura en Valencia | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Obtén hasta 6.000 euros en 24 horas empeñando tu coche en Valencia con Ibancar, realiza todo el proceso 100% online.'},
        {name:'keywords', content:'empeño de coches valencia'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-between(xl,xxl) {
  h3 {
    font-size: 1.25rem;
  }
}
</style>