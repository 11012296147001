<template>
  <div>
    <Section1 :keyWord="'La mejor solución para empeñar tu vehículo en Barcelona'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>La mejor solución para empeñar tu coche en Barcelona</strong></h2>
      <p>¿Todavía no conoces el servicio de <strong>empeño de coches Barcelona</strong> de Ibancar? Si necesitas dinero urgente en tu cuenta en menos de 24 horas es el método más fiable y seguro para conseguir liquidez en tu cuenta bancaria.</p>
      <p>Lo mejor del crédito es que todo el proceso se completa de manera online en unos cuantos clics.</p>
      <p>La ciudad condal está llena de oportunidades, y gracias al préstamo por <strong>empeño de coches Barcelona</strong> vas a poder iniciar cualquier proyecto con la máxima solvencia.</p>

      <h3 class="naranja"><strong>Empeñar coche en Barcelona</strong></h3>
      <p>Este es un servicio ofrecido por Ibancar a todos sus clientes para proporcionarles liquidez inmediata en su cuenta a cambio de poner como garantia para el crédito su vehículo.</p>
      <p>Este aval es testimonial, ya que en el programa de <strong>empeño de coches Barcelona</strong>, el usuario no pierde en ningún momento sus derechos como propietario del vehículo.</p>

      <h3 class="naranja"><strong>Empeña tu coche en Barcelona y sigue usándolo</strong></h3>
      <p>Una de las mayores ventajas del <strong>empeño de coches Barcelona</strong> de Ibancar es que vas a poder seguir conduciendo tu vehículo sin ningún tipo de restricción.</p>
      <p>Otras compañías que ofrecen empeño de coches Barcelona te obligan a dejar el automóvil en un depósito o a pagar un alquiler para conducirlo. Y de esta manera no amortizas nunca la cantidad prestada.</p>
      <p>En Ibancar te garantizamos las mejores condiciones en los servicios de empeño de coches Barcelona:</p>
      <ul>
          <li>En ningún momento pierdes la titularidad de tu vehículo</li>
          <li>Puedes seguir conduciendo sin limitación de kilometraje.</li>
          <li>No tienes que entregar una copia de las llaves ni la documentación original del coche.</li>
          <li>No hay alquileres ni renting</li>
          <li>Sin retiradas ni aparcamientos en depósitos.</li>
      </ul>

      <h3 class="naranja"><strong>¿Cómo empeñar tu coche en Barcelona con Ibancar?</strong></h3>
      <p>Vamos con el paso a paso para acceder al programa de <strong>empeño de coches Barcelona</strong> con Ibancar. El proceso es muy simple y se completa desde cualquier dispositivo con conexión a internet:</p>
      <ul>
          <li>Rellena el formulario de la web de Ibancar con la cantidad que necesitas.</li>
          <li>En unos minutos recibirás una propuesta de crédito personalizada en tu correo electrónico.</li>
          <li>Acepta la propuesta y aporta la documentación adicional necesaria.</li>
          <li>Dinero inmediato  en tu cuenta bancaria.</li>
      </ul>
      <p>Como puedes comprobar, conseguir liquidez a través del servicio de <strong>empeño de coches Barcelona</strong> es muy fácil.</p>
      <p>Para ayudarte ante cualquier duda que pueda surgirte durante la solicitud se te asignará un gestor personal que te ofrecerá asesoramiento personalizado y todo el soporte que necesitas.</p>

      <h3 class="naranja"><strong>Ventajas de empeñar tu coche en Ibancar</strong></h3>
      <p>Estas son algunas de las ventajas exclusivas a las que tienes acceso a través del programa de <strong>empeño de coches Barcelona</strong> de Ibancar:</p>
      <ul>
          <li>Concesión del crédito por <strong>empeño de coches Barcelona</strong> a personas incluidas en los ficheros de impagados de la Asnef.</li>
          <li>Elección de la fecha de pago, del método de pago y cinco días de cortesía para satisfacer la cuantía.</li>
          <li>Pack de servicios exclusivos entre los que se incluye una revisión técnica manual de mantenimiento y 2 informes de tráfico al año </li>
          <li>Amortización del préstamo desde la primera cuota.</li>
          <li>Servicio de compra de coche en caso de no poder satisfacer el importe del crédito.</li>
      </ul>


      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h4 class="title-h1 text-center">Préstamo por tu coche</h4>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4">
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamo por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../empenar-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">La mejor solución al empeño de Coches. Préstamos rápidos con tu coche sin dejar de usarlo</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Dinero rápido con tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamos avalando tu coche en el mismo día.</p>
              </div>
            </a>
          </div>
      </div>
      <!--  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
              <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
                <div class="card-body">
                  <h3 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h3>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
                </div>
              </a>
          </div>
      </div> 
<!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito aval coche con Asnef</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito con aval</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCocheBarcelona',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Empeña tu coche en Barcelona ▷ 100% online, sin entregar vehículo y sin cambio de titular',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Obtén hasta 6.000 euros en 24 horas empeñando tu coche en Barcelona con Ibancar, realiza todo el proceso 100% online.'},
        {name:'keywords', content:'empeño de coches barcelona'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-between(xl,xxl) {
  h3 {
    font-size: 1.25rem;
  }
}
</style>