<template>
  <div>
    <Section1 :keyWord="'La mejor solución para empeñar tu coche en Málaga'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Empeñar Coche de forma fácil, segura en Malaga</strong></h2>
      <p>¿Estás viviendo en Málaga y necesitas dinero urgente en tu cuenta? Estás de enhorabuena. Gracias al servicio  <strong>“empeña tu coche Málaga”</strong> de Ibancar podrás contar con liquidez en tu cuenta en menos de 24 horas y completar todo el proceso de manera 100% online.</p>

      <h3 class="naranja"><strong>Empeña tu coche Málaga y sigue conduciendo sin ningún tipo de restricción</strong></h3>
      <p>Esta es una de las dudas más habituales de los clientes a la hora de interesarse por el crédito aval de coche de Ibancar. Al utilizar el servicio <strong>empeña tu coche Málaga</strong> no necesitas entregar tu vehículo ni dejarlo en un depósito.</p>
      <p>Tampoco tienes que entregar una copia de las llaves ni los documentos originales del coche. Podrás seguir utilizando tu coche sin pagar un renting ni un alquiler. Pero no solo eso,Ibancar ofrece servicios exclusivos a sus clientes al contratar la modalidad de préstamo empeña tu coche Málaga que incluyen:</p>
      <ul>
          <li>Garantía de amortización por siniestro total</li>
          <li>Revisión técnica anual de mantenimiento</li>
          <li>Estudio personalizado para solicitar un aumento del crédito</li>
          <li>Servicio de citas para la Inspección Técnica de Vehículos (ITV)</li>
          <li>Área de clientes en la web de Ibancar con información completa de tu préstamo</li>
          <li>5 días de cortesía para satisfacer las cuotas de la deuda</li>
          <li>Gestor personal para ofrecerte asesoramiento y resolver cualquier duda que pueda surgirte</li>
          <li>Diferentes métodos de pago disponibles.</li>
          <li>Servicio de compra de coche en caso de no poder satisfacer la deuda</li>
          <li>Elección de la fecha de los pagos por parte del cliente.</li>
      </ul>

      <h3 class="naranja"><strong>¿Cómo puedo solicitar el servicio empeña tu coche Málaga en Ibancar?</strong></h3>
      <p>Solicitar el servicio <strong>empeña tu coche Málaga</strong> en Ibancar es muy sencillo. Lo mejor de todo es que el proceso se puede completar de manera online desde la comodidad de tu hogar.</p>
      <ol>
          <li>Rellena el formulario de solicitud indicando la matrícula de tu coche y tus datos de contacto y la cantidad que necesitas.</li>
          <li>Recibirás una propuesta de préstamo personalizada</li>
          <li>Envía la documentación requerida. El equipo de Ibancar la revisará al instante.</li>
          <li>El dinero estará disponible en tu cuenta en menos de 24 horas.</li>
      </ol>

      <h3 class="naranja"><strong>Estoy en una lista de impagados, ¿Puedo aprovecharme del servicio de empeña tu coche Málaga de Ibancar?</strong></h3>
      <p>El préstamo <strong>empeña tu coche Málaga</strong> de Ibancar está disponible para todos los clientes que puedan aportar una fuente de ingresos para hacer frente al pago de las cuotas.</p>
      <p>A diferencia de las entidades bancarias tradicionales, el hecho de estar incluido en una lista de morosos o impagados como la de la Asnef no es un motivo suficiente para denegar un crédito en Ibancar.</p>

      <h3 class="naranja"><strong>¿Qué documentación tengo que aportar para acceder al crédito empeña tu coche Málaga?</strong></h3>
      <p>Podrás enviar la documentación requerida para acceder a tu préstamo <strong>empeña tu coche Málaga</strong> a través del correo electrónico o mediante el servicio de mensajería instantánea de WhatsApp.</p>
      <p>La documentación personal que debes aportar incluye el DNI, un documento justificante de ingresos y un justificante de un número de cuenta en el que aparezcas como titular. En cuanto al vehículo, la documentación para disfrutar del dinero en tu cuenta en menos de 24 horas es la siguiente:</p>
      <ul>
          <li>Permiso de circulación</li>
          <li>Ficha técnica escaneada</li>
          <li>Foto del cuentakilómetros + DNI</li>
          <li>4 fotos del coche y un video</li>
      </ul>

      <h3 class="naranja"><strong>¿Esta alternativa para empeñar coche está disponible en toda España?</strong></h3>
      <p>Por supuesto. Puedes comenzar con tu solicitud desde cualquier ubicación de la península o las Islas Baleares de manera online y recibir el dinero en tu cuenta en menos de 24 horas.</p>

      <h3 class="naranja"><strong>¿No hay más opción que empeñar el coche si estoy en una lista de morosos?</strong></h3>
      <p>Ibancar te ofrece la posibilidad de recibir un crédito a tu medida incluso si te han incluido en la lista ASNEF.</p>
      <p>Debes tener en cuenta que <strong>empeñar coche</strong> en Ibancar es un acto prácticamente simbólico, ya que vas a poder seguir disfrutando de tu vehículo sin ningún tipo de restricción.</p>
      <p>Esta es una característica única en el mercado de créditos ágiles, por lo que <strong>empeñar coche</strong> en Ibancar es probablemente la manera más sencilla y segura de conseguir liquidez para tu cuenta en cuestión de horas.</p>

      <h3 class="naranja"><strong>¿Qué pasa si no llego a una cuota?</strong></h3>
      <p>El crédito para <strong>empeñar coche</strong> de Ibancar se ajusta a las características de tu situación personal. Durante el proceso de solicitud contarás con asesoramiento personalizado para que nunca te veas en la situación de no poder satisfacer el pago de tus cuotas. </p>

      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
        <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>
<!-- fila 1  -->
      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4">
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamo por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../empenar-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">La mejor solución al empeño de Coches. Préstamos rápidos con tu coche sin dejar de usarlo</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Dinero rápido con tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      <!--  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
      </div> 
<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito aval coche con Asnef</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito con aval</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCocheMalaga',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Empeña tu coche en Malaga ▷ 100% online, sin entregar vehículo y sin cambio de titular',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'¿Estás viviendo en Málaga y necesitas dinero urgente en tu cuenta? Estás de enhorabuena. Gracias al servicio  “empeña tu coche Málaga” de Ibancar podrás contar con liquidez en tu cuenta en menos de 24 horas y completar todo el proceso de manera 100% online.'},
        {name:'keywords', content:'empeña tu coche malaga'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-between(xl,xxl) {
  h3 {
    font-size: 1.25rem;
  }
}
</style>