<template>
  <div>
    <Section1 :keyWord="'Alternativa fácil y segura a empeñar coche en Girona'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Alternativa fácil y segura a empeñar coche en Girona</strong></h2>
      <p>¿Estás viviendo en Girona y necesitas dinero urgente? Ibancar te ofrece un servicio personalizado, sencillo y rápido; un préstamo personal con tu coche como aval. ¿Necesitas un poco más de información? ¡Sigue leyendo!</p>
      <p>Existen muchos métodos de conseguir financiación, pero sin duda el de utilizar un coche como aval se ha convertido en una de las fórmulas más comunes en los últimos tiempos. Desde Ibancar, hace 8 años que damos servicio a aquellas personas que buscan una alternativa al <strong>empeño de coche</strong>. El servicio que ofrece Ibancar, se distingue del resto debido a que en él, tu vehículo sólo actúa como garantía de pago testimonial.  </p>
      <p>A diferencia de otras empresas del sector, no perderás ningún derecho sobre tu vehículo, completarás el trámite de manera 100% online y no importará si te han incluido en algún fichero de impagados como el de la ASNEF. ¡Así de sencillo es acceder al servicio de Ibancar!</p>

      <h3 class="naranja"><strong>Alternativa a empeñar coche en Girona</strong></h3>
      <p>¿Has intentado conseguir un préstamo a través de tu entidad bancaria? El proceso se dilata demasiado en el tiempo. Con el servicio alternativo de Ibancar la solicitud se realizará totalmente online; es un método rápido, sencillo e intuitivo. Además, el equipo de asesores de Ibancar estará a tu disposición para resolver cualquier duda que tengas a lo largo de todo el trámite. </p>
      <p>Las ventajas de la alternativa a empeñar coches en Girona, que ofrece Ibancar es eficaz y transparente. A diferencia de otros servicios, el de Ibancar no implica: dejar las llaves de tu vehículo, guardarlo en un depósito hasta el pago completo del préstamo o un cambio de titularidad.</p>

      <h3 class="naranja"><strong>La alternativa a empeñar tu coche en Girona, te permite seguir usándolo </strong></h3>
      <p>En las líneas anteriores te hemos comentado algunas de las ventajas que te ofrece el servicio alternativo de empeñar coche en Girona. ¿Quieres saber más ventajas del servicio que te ofrece Ibancar? </p>
      <p>A diferencia de otros servicios, con el préstamo personal de Ibancar, a partir del cual tu coche servirá como aval, podrás seguir utilizando tu vehículo sin ningún tipo de restricción. Además, como conductor no tendrás que abonar una cuota de renting, tal y como establecen otras empresas. La única obligatoriedad que se impone con este método es que no podrás vender el coche mientras tengas el préstamo activo.</p>
      <p>¿Tu preocupación es aparecer en el Registro de Aceptaciones Impagadas (RAI) o en la Asociación Nacional de Establecimientos Financieros de Crédito (ASNEF)? ¡Para Ibancar no será un problema! Aunque el cliente figure en el RAI o ASNEF, le podremos ayudar. Esta es otra de las ventajas que te ofrece el servicio del préstamo personal con coche como aval de Ibancar. </p>
      <p>Al ser un servicio online, puedes conseguir hasta 6.000€ hoy, sin salir de casa. Podrás consultar a nuestro equipo altamente cualificado en cualquier momento del trámite. Estarán a tu disposición para ofrecerte tantos detalles como precises. </p>

      <h3 class="naranja"><strong>¿Cómo solicitar la alternativa a empeñar coche en Girona?</strong></h3>
      <p>¿Has decidido ya usar tu coche como aval con Ibancar?¡Excelente decisión! Vamos a explicarte cómo realizar los sencillos pasos para poder solicitar el dinero de la manera más rápida:</p>
      <ol>
        <li>Rellena el formulario de Ibancar con tus datos y la cantidad de dinero que necesitas.</li>
        <li>Una vez terminado recibirás una propuesta de préstamo preaprobado.</li>
        <li>Envía la documentación adicional necesaria para formalizar el préstamo.</li>
        <li>Nuestro equipo de profesionales verificará los documentos a la mayor brevedad.</li>
        <li>Disfruta del dinero en tu cuenta en el mismo día.</li>
      </ol>
      <h3 class="naranja"><strong>Documentación necesaria para acceder al préstamo personal de Ibancar</strong></h3>
      <p>Antes de proceder a solicitar el préstamo con aval de coche de Ibancar, debes saber que documentación básica deberás de presentar. </p>
      <p>Como titular del vehículo:</p>
      <ul>
        <li>Fotografía del DNI – ambas caras -, sobre un fondo blanco.</li>
        <li>Última nómina, pensión o certificado de autónomo</li>
        <li>Fotografía estilo selfie junto con el DNI para probar la identidad del demandante.</li>
      </ul>
      <p>Con respecto al vehículo, se deberá presentar:</p>
      <ul>
        <li>Permiso de circulación y ficha técnica.</li>
        <li>Fotografía del cuentakilómetros con el DNI del conductor.</li>
        <li>Material audiovisual: cinco fotografías y un vídeo en perfecta calidad, de ambos lados, parte anterior y posterior del vehículo. Para poder apreciar su estado.</li>
      </ul>
      <p>Así de sencillo es conseguir dinero de forma rápida a través de la alternativa al <strong>empeño de coches en Girona</strong> que puede ofrecerte Ibancar. </p>
      <p>A continuación resumimos las principales ventajas del servicio para nuestros clientes.</p>

      <h3 class="naranja"><strong>rVentajas de escoger el servicio alternativo al empeño de coche que ofrece Ibancar</strong></h3>
      <p>Desde el momento en el que rellenas la solicitud en la web de Ibancar se te asigna un gestor personal para asesorarte y resolver cualquier duda. Este te acompañará desde el inicio hasta el final del trámite.</p>
      <p>Una vez que recibas el dinero, y ya eres cliente de Ibancar de manera oficial cuentas con un área personal en la web para cualquier trámite relacionado con tu préstamo. </p>
      <p>Una de las cuestiones que más preocupa a los usuarios que deciden recurrir al sistema de préstamos con aval de coche en Girona es que cuando optes por la alternativa que te ofrece Ibancar, la cancelación del préstamo se efectuará de forma sencilla en el momento que lo desees, sin largas esperas ni molestos trámites.</p>
      <p>De forma resumida, te señalamos las mayores ventajas del método alternativo al empeño de coches Girona:</p>
      <ul>
        <li> Eliges el día del mes que más te conviene para abonar la cuota</li>
        <li>Tienes 5 días de cortesía para formalizar el pago</li>
        <li>Puedes solicitar periodos de carencia</li>
        <li>Cancela el préstamo de manera sencilla cuando lo desees</li>
        <li>Si no puedes hacer frente a los pagos, Ibancar pone a tu disposición un servicio de venta del vehículo.</li>
      </ul>
      <p>Como puedes comprobar, son múltiples los motivos por los que confiar en Ibancar. ¡Es garantía de calidad y servicio!</p>


      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h4 class="title-h1 text-center">Préstamo por tu coche</h4>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4">
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamo por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../empenar-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">La mejor solución al empeño de Coches. Préstamos rápidos con tu coche sin dejar de usarlo</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Dinero rápido con tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamos avalando tu coche en el mismo día.</p>
              </div>
            </a>
          </div>
      </div>
      <!--  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
              <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
                <div class="card-body">
                  <h3 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h3>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
                </div>
              </a>
          </div>
      </div> 
<!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito aval coche con Asnef</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito con aval</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCocheBarcelona',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Empeñar Coche de forma fácil, segura ▷ Girona | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Obtén hasta 6.000 euros en 24 horas empeñando tu coche en Girona con Ibancar, realiza todo el proceso 100% online.'},
        {name:'keywords', content:'empeño de coches girona'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-between(xl,xxl) {
  h3 {
    font-size: 1.25rem;
  }
}
</style>