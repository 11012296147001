<template>
  <div>
    <Section1 :keyWord="'Alternativa fácil y segura a empeñar coche en Zaragoza'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Alternativa fácil y segura a empeñar coche en Zaragoza</strong></h2>
      <p>¿Te encuentras en una situación en la que necesitas dinero urgente en Zaragoza? ¿Has sido incluido en una lista de impagados y te han denegado un préstamo en el banco? ¡Ibancar tiene la solución! </p>
      <p>Te presentamos la alternativa a <strong>empeñar el coche en Zaragoza</strong> que te permitirá obtener la liquidez que necesitas en cuestión de horas y con un proceso 100% online. Descubre el crédito con aval de coche de Ibancar, una opción que te permitirá utilizar tu coche como garantía sin restricciones.</p>

      <h3 class="naranja"><strong>Alternativa a empeñar coche en Zaragoza</strong></h3>
      <p>Hay varias razones comunes por las que a muchas personas les surge la necesidad  de obtener dinero para hacer frente a pagos urgentes o para invertir en estudios, viajes o en cualquier otro proyecto personal. </p>
      <p>Sin embargo, muchos son los que escogen la opción de <strong>empeñar el coche en Zaragoza</strong> como la solución, encontrándose con empresas que imponen condiciones abusivas, como el cambio de titularidad del vehículo, el traslado a un depósito hasta saldar la deuda o incluso el alquiler del coche al propietario como condición para seguir conduciéndolo. Con la alternativa al empeño de coche que ofrece Ibancar, esto no sucede. </p>
      <p>Algunas de las ventajas que ofrece el servicio de Ibancar es que el conductor no deberá de abonar ninguna cuota de renting y además, podrá usar el vehículo a lo largo del trámite. La única obligatoriedad que se impone con este método es que no se puede vender el coche mientras tengas el préstamo activo.</p>

      <h3 class="naranja"><strong>La alternativa a empeñar tu coche en Zaragoza, te permite seguir usándolo </strong></h3>
      <p>¿Sabías que existe una alternativa más inteligente y segura para obtener liquidez en Zaragoza sin tener que aceptar condiciones abusivas por parte de empresas de crédito o casas de empeño? Te presentamos el crédito con aval de coche de Ibancar,  que te permitirá conseguir el dinero que necesitas manteniendo la titularidad de tu vehículo y sin ningún tipo de restricciones o pagos para utilizarlo.</p>
      <p>Es por esta razón que el conductor no deberá de abonar ninguna cuota de renting y además, podrá usar el vehículo a lo largo del trámite. La única obligatoriedad que se impone con este método es que no se puede vender el coche mientras tengas el préstamo activo.</p>
      <p>¿Te preocupa aparecer en Registro de Aceptaciones Impagadas (RAI)  o en la Asociación Nacional de Establecimientos Financieros de Crédito (ASNEF)? ¡Para Ibancar no será un problema! Aunque el cliente figure en el RAI o ASNEF, le podremos ayudar. Esta es otra de las ventajas que te ofrece el servicio del préstamo personal con coche como aval de Ibancar. </p>
      <p>Al ser un servicio online, puedes conseguir hasta 6.000€ hoy, sin salir de casa. Además, contarás con un gestor asociado durante todo el proceso, es decir, hablarás siempre con la misma persona, sin tener que contar lo mismo a varias personas y con soluciones inmediatas.</p>
      <p>La tramitación se realiza en tan solo unos minutos y es completamente online. Te explicamos cómo hacerlo paso a paso.</p>

      <h3 class="naranja"><strong>¿Cómo solicitar la alternativa a empeñar coche en Zaragoza?</strong></h3>
      <p>El proceso para conseguir liquidez inmediata con el crédito de aval de coche en Zaragoza de Ibancar es simple y rápido. Solo necesitas una conexión a internet y ser el propietario del vehículo.</p>
      <ol>
        <li>Rellena el formulario de Ibancar con tus datos y la cantidad de dinero que necesitas.</li>
        <li>Una vez terminado recibirás una propuesta de préstamo preaprobado.</li>
        <li>Envía la documentación adicional necesaria para formalizar el préstamo.</li>
        <li>Nuestro equipo de profesionales verificará los documentos a la mayor brevedad.</li>
        <li>Disfruta del dinero en tu cuenta en el mismo día.</li>
      </ol>
      <h3 class="naranja"><strong>Documentación necesaria para acceder al préstamo personal de Ibancar</strong></h3>
      <p>Antes de proceder a solicitar el préstamo con aval de coche de Ibancar, debes saber que documentación básica deberás de presentar. </p>
      <p>Como titular del vehículo:</p>
      <ul>
        <li>Fotografía del DNI – ambas caras -, sobre un fondo blanco.</li>
        <li>Última nómina, pensión o certificado de autónomo</li>
        <li>Fotografía estilo selfie junto con el DNI para probar la identidad del demandante.</li>
      </ul>
      <p>Con respecto al vehículo, se deberá presentar:</p>
      <ul>
        <li>Permiso de circulación y ficha técnica.</li>
        <li>Fotografía del cuentakilómetros con el DNI del conductor.</li>
        <li>Material audiovisual: cinco fotografías y un vídeo en perfecta calidad, de ambos lados, parte anterior y posterior del vehículo. Para poder apreciar su estado.</li>
      </ul>

      <h3 class="naranja"><strong>Ventajas del crédito con aval de coche en Zaragoza</strong></h3>
      <ul>
        <li>Proceso 100% online: Sin trámites presenciales ni papeleos innecesarios.</li>
        <li>Disponible para personas incluidas en listas de impagados: En nuestra empresa, evaluamos cada solicitud de manera individual, sin descartar a quienes figuran en registros de morosos.</li>
        <li>Asesoramiento personalizado: Un gestor personal  estará a tu disposición durante todo el proceso para brindarte el apoyo necesario.</li>
        <li>Concesión inmediata: Obtén la respuesta a tu solicitud en cuestión de minutos.</li>
        <li>Sin cambios en la titularidad del coche: Podrás seguir siendo el dueño y conductor de tu vehículo.</li>
        <li>Flexibilidad en la devolución: Ofrecemos periodos de carencia y opciones de amortización adaptadas a tus necesidades.</li>
        <li>Revisión anual mecánica de Midas incluida: Garantizamos el buen estado de tu coche durante todo el tiempo del préstamo.</li>
      </ul>
      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h4 class="title-h1 text-center">Préstamo por tu coche</h4>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamo por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Dinero rápido con tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      <!--  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
      </div> 
<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito aval coche con Asnef</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito con aval</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCocheZaragoza',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Empeñar Coche de forma fácil, segura en Zaragoza | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Obtén hasta 6.000 euros en 24 horas empeñando tu coche en Zaragoza con Ibancar, realiza todo el proceso 100% online.'},
        {name:'keywords', content:'empeño de coches zaragoza'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-between(xl,xxl) {
  h3 {
    font-size: 1.25rem;
  }
}
</style>