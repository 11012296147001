<template>
  <div>
    <Section1 :keyWord="'Alternativa fácil y segura a empeñar coche en Alicante'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Alternativa fácil y segura a empeñar coche en Alicante</strong></h2>
      <p>¿Estás viviendo en Alicante y necesitas dinero urgente para satisfacer un pago o para financiar un proyecto personal? ¡Ibancar tiene la solución! Desde hace 8 años ofrecemos la alternativa al empeño de coche. </p>
      <p>Desde Ibancar te ofrecemos un préstamo personal usando tu coche como garantía de aval. Después de realizar la solicitud sencilla y totalmente online, recibirás en un plazo máximo de 24 horas la liquidez que necesitas. Además, con el servicio que te ofrece Ibancar, podrás seguir utilizando tu vehículo, a su vez no implicará que tengas que cambiar la titularidad de este. </p>
      <p>Cualquier persona que posea ingresos recurrentes podrá solicitar la alternativa al <strong>empeño de coche</strong>. La opción que te propone Ibancar garantiza la concesión del préstamo en todo momento a pesar de estar incluido en algún fichero de morosidad o impagados como el de la Asociación Nacional de Establecimientos Financieros de Crédito (ASNEF) o el del Registro de Aceptaciones Impagadas (RAI).</p>

      <h3 class="naranja"><strong>Alternativa a empeñar coche en Alicante</strong></h3>
      <p>¿Has intentado antes obtener liquidez mediante una entidad bancaria o una empresa de empeño? Si es así, puedes haberte encontrado alguna entidad que te obligaba a dejar el coche en un depósito hasta que cumplieses con todos los pagos, o que te ponía como condición pagar un alquiler o un renting para poder conducirl</p>
      <p>Encontrar otras opciones al <strong>empeño de tu coche en Alicante</strong>, puede ser complicado. ¡En Ibancar te ofrecemos la alternativa! El préstamo con aval de coche. Olvídate de condiciones abusivas: Ibancar te ofrece la alternativa al empeño de coches. Una de las ventajas del servicio de Ibancar, es que podrás seguir disfrutando de tu coche, sin ningún tipo de restricción. No se exigirá en ningún caso, el cambio de la titularidad o pagar una cuota de renting de forma mensual, tal y como exigen otras empresas. </p>

      <h3 class="naranja"><strong>La alternativa a empeñar tu coche en Alicante, te permite seguir usándolo</strong></h3>
      <p>Anteriormente te hemos comentado algunas de las ventajas de la alternativa al empeño de coches, que te ofrece Ibancar. A continuación te explicamos más acerca del servicio y sus ventajas. </p>
      <p>¿Te preocupa aparecer en Registro de Aceptaciones Impagadas (RAI)  o en la Asociación Nacional de Establecimientos Financieros de Crédito (ASNEF)? ¡Para Ibancar no será un problema! Aunque el cliente figure en el RAI o ASNEF, le podremos ayudar. Esta es otra de las ventajas que te ofrece el servicio del préstamo personal con coche como aval de Ibancar. </p>
      <p>Al ser un proceso online y sin demasiados trámites, cuando te decidas a solicitar el servicio alternativo a <strong>empeñar tu coche en Alicante</strong>, podrás obtener tu dinero en 24 horas. Puedes conseguir hasta 6.000€ hoy, sin salir de casa. Nuestro equipo de gestores estarán a tu disposición para ofrecerte tantos detalles como precises. Además, al ser un servicio personalizado se te asignará un gestor, este será el mismo que te acompañará durante todo el trámite. </p>

      <h3 class="naranja"><strong>¿Cómo solicitar la alternativa a empeñar coche en Alicante?</strong></h3>
      <p>¿Has decidido ya usar tu coche como aval con Ibancar?¡Excelente decisión! Vamos a explicarte cómo realizar los sencillos pasos para poder solicitar el dinero de la manera más rápida:</p>
      <ol>
        <li>Rellena el formulario de Ibancar con tus datos y la cantidad de dinero que necesitas.</li>
        <li>Una vez terminado recibirás una propuesta de préstamo preaprobado.</li>
        <li>Envía la documentación adicional necesaria para formalizar el préstamo.</li>
        <li>Nuestro equipo de profesionales verificará los documentos a la mayor brevedad.</li>
        <li>Disfruta del dinero en tu cuenta en el mismo día.</li>
      </ol>
      <h3 class="naranja"><strong>Documentación necesaria para acceder al préstamo personal de Ibancar</strong></h3>
      <p>Antes de proceder a solicitar el préstamo con aval de coche de Ibancar, debes saber que documentación básica deberás de presentar. </p>
      <p>Como titular del vehículo:</p>
      <ul>
        <li>Fotografía del DNI – ambas caras -, sobre un fondo blanco.</li>
        <li>Última nómina, pensión o certificado de autónomo</li>
        <li>Fotografía estilo selfie junto con el DNI para probar la identidad del demandante.</li>
      </ul>
      <p>Con respecto al vehículo, se deberá presentar:</p>
      <ul>
        <li>Permiso de circulación y ficha técnica.</li>
        <li>Fotografía del cuentakilómetros con el DNI del conductor.</li>
        <li>Material audiovisual: cinco fotografías y un vídeo en perfecta calidad, de ambos lados, parte anterior y posterior del vehículo. Para poder apreciar su estado.</li>
      </ul>


      <h3 class="naranja"><strong>Ventajas de escoger el servicio alternativo al empeño de coche que ofrece Ibancar</strong></h3>
        <p>¿Sabías que la alternativa a empeñar coche en Alicante a través del préstamo con aval de coche de Ibancar tienes derecho a servicios exclusivos para clientes?</p>
        <p>Una de las mayores ventajas es que,en Ibancar no tenemos en cuenta que hayas sido incluido en un fichero de impagados. Un justificante de ingresos será suficiente para conceder el crédito.</p>
        <p>Además, vas a contar con un asesor personal que te asistirá durante todo el proceso y un espacio en nuestro sitio web de clientes desde el que vas a poder consultar toda la información sobre el préstamo.</p>
        <p>Entre los servicios exclusivos para clientes de Ibancar se encuentran:</p>
        <ul>
          <li>5 días de cortesía para efectuar los pagos.</li>
          <li>Fácil cancelación del préstamo para ahorrarse los intereses.</li>
          <li>Disponibilidad de meses de dificultad.</li>
          <li>Elección de la fecha mensual de pago que más te convenza.</li>
          <li>Revisión técnica anual de mantenimiento.</li>
          <li>Garantía de amortización por siniestro total.</li>
        </ul>
        <p>Nunca solicitar un préstamo con aval de coche había sido tan sencillo como con Ibancar. No lo dudes y pide tu información sin compromiso alguno. ¡Ibancar te ofrece la alternativa rápida, ágil y plenamente segura al <strong>empeño de tu coche en Alicante!</strong></p>

      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h4 class="title-h1 text-center">Préstamo por tu coche</h4>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamo por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Dinero rápido con tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      <!--  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-valencia" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche en Alicante</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Alicante. Recibe dinero por tu coche en Alicante.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
      </div> 

<!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-por-tu-coche-alicante" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos por tu coche Alicante</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-aval-coche-valencia" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos aval coche &nbsp; Alicante</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
    
      </div>

<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito aval coche con Asnef</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito con aval</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCocheAlicante',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Empeñar Coche de forma fácil, segura en Alicante | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Obtén hasta 6.000 euros en 24 horas empeñando tu coche en Alicante con Ibancar, realiza todo el proceso 100% online.'},
        {name:'keywords', content:'empeño de coches alicante'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-between(xl,xxl) {
  h3 {
    font-size: 1.25rem;
  }
}
</style>