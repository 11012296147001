<template>
  <div>
    <Section1 :keyWord="'Alternativa fácil y segura a empeñar coche en Madrid'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Alternativa fácil y segura a empeñar coche en Madrid</strong></h2>
      <p>¿Vives en Madrid y necesitas dinero urgente? La alternativa que te ofrece Ibancar al <strong>empeño de tu coche en Madrid</strong> es rápida, segura y 100% online. Además, tendrás la liquidez que precises en tu cuenta en menos de 24 horas.</p>
      <p>¿Sabes las principales ventajas de escoger el servicio de Ibancar? A diferencia de empresas de microcréditos o dedicadas al mundo del empeño, Ibancar te brinda una alternativa: la posibilidad de acceder a un préstamo personal, con tu coche como aval. Este servicio te permite seguir utilizando tu vehículo, sin miedo a perderlo y sin el requisito de entregar la documentación original del mismo. </p>
      <p>Te explicamos todos los detalles a continuación:</p>

      <h3 class="naranja"><strong>Alternativa a empeñar coche en Madrid</strong></h3>
      <p>El servicio alternativo al <strong>empeño de tu coche</strong> que te ofrece Ibancar es el sistema más rápido, eficaz y seguro para obtener liquidez inmediata. Contamos con 8 años dando servicio a miles de clientes. Es por esta razón que Ibancar se ha convertido en la opción favorita de miles de personas, que necesitan obtener liquidez de manera urgente y que solo tienen su vehículo como garantía. Así pues, en menos de 24 horas, obtendrán lo que necesitan sin perder su automóvil.</p>
      <p>Hay entidades bancarias te exigen infinidad de documentos y firmas antes de concederte un préstamo. Por otro lado están las empresas que te ofrecen empeñar tu coche pero te obligan a dejarlo en un depósito hasta que satisfagas la deuda. La alternativa a estos servicios que te ofrece Ibancar, no vas a tener este problema.</p>
      <p>Con Ibancar, la solicitud se realiza de manera 100% online, por lo que será un método sencillo e intuitivo. Además, en caso de cualquier problema, podrás consultar a nuestro equipo cualificado de asesores que te ayudará para poder cerrar tu operación. El proceso se completa en cuestión de minutos. Estamos a tu disposición y puedes contactar con nosotros para que te ofrezcamos tantos detalles como precises.</p>


      <h3 class="naranja"><strong>La alternativa a empeñar tu coche en Madrid, te permite seguir usándolo </strong></h3>
      <p>Hasta ahora te hemos comentado algunas de las ventajas que tiene escoger el servicio de Ibancar. ¿Todavía no te has decidido? ¡Te lo explicamos mejor!</p>
      <p>Con el servicio alternativo al <strong>empeño de coche</strong>, que te ofrece Ibancar, tu vehículo actúa sólo como aval del préstamo, lo hace de forma testimonial. Eso querrá decir, que podrás seguir conduciendo tu vehículo sin ningún tipo de restricción. Además, a diferencia de otros </p>
      <p>servicios de empeño de coches, con el servicio alternativo de Ibancar no tienes que entregar una copia de las llaves, no hará falta cambiar la titularidad, ni tampoco la entrega de la documentación original de tu vehículo. La única condición que ponemos es que el vehículo no se puede vender durante el transcurso del trámite. </p>
      <p>¿Te preocupa aparecer en Registro de Aceptaciones Impagadas (RAI)  o en la Asociación Nacional de Establecimientos Financieros de Crédito (ASNEF)? ¡Para Ibancar no será un problema! Aunque el cliente figure en el RAI o ASNEF, le podremos ayudar. Esta es otra de las ventajas que te ofrece el servicio del préstamo personal con coche como aval de Ibancar. </p>
      <p>Al ser un servicio online, puedes conseguir hasta 6.000€ hoy, sin salir de casa. Podrás consultar a nuestro equipo altamente cualificado en cualquier momento del trámite. Estarán a tu disposición para ofrecerte tantos detalles como precises. </p>

      <h3 class="naranja"><strong>¿Cómo solicitar la alternativa a empeñar coche en Madrid?</strong></h3>
      <p>¿Has decidido ya usar tu coche como aval con Ibancar?¡Excelente decisión! Vamos a explicarte cómo realizar la solicitud para poder recibir el dinero de la manera más rápida:</p>
      <ol>
        <li>Rellena el formulario de Ibancar con tus datos y la cantidad de dinero que necesitas.</li>
        <li>Una vez terminado recibirás una propuesta de préstamo preaprobado.</li>
        <li>Envía la documentación adicional necesaria para formalizar el préstamo.</li>
        <li>Nuestro equipo de profesionales verificará los documentos a la mayor brevedad.</li>
        <li>Disfruta del dinero en tu cuenta en el mismo día.</li>
      </ol>
      <h3 class="naranja"><strong>Documentación necesaria para acceder al préstamo personal de Ibancar</strong></h3>
      <p>Antes de proceder a solicitar el préstamo con aval de coche de Ibancar, debes saber que documentación básica deberás de presentar. </p>
      <p>Como titular del vehículo:</p>
      <ul>
        <li>Fotografía del DNI – ambas caras -, sobre un fondo blanco.</li>
        <li>Última nómina, pensión o certificado de autónomo</li>
        <li>Fotografía estilo selfie junto con el DNI para probar la identidad del demandante.</li>
      </ul>
      <p>Con respecto al vehículo, se deberá presentar:</p>
      <ul>
        <li>Permiso de circulación y ficha técnica.</li>
        <li>Fotografía del cuentakilómetros con el DNI del conductor.</li>
        <li>Material audiovisual: cinco fotografías y un vídeo en perfecta calidad, de ambos lados, parte anterior y posterior del vehículo. Para poder apreciar su estado.</li>
      </ul>

      <h3 class="naranja"><strong>Ventajas de escoger el servicio alternativo al empeño de coche que ofrece Ibancar</strong></h3>
      <p>La facilidad para solicitar el préstamo personal con aval de coche de Ibancar, no es la única ventaja que te hace confiar en Ibancar. </p>
      <p>Desde el primer contacto con nosotros, se te asigna un gestor personal que te acompañará desde el inicio hasta la finalización del trámite. Este estará a tu disposición para asesorarte en todo aquello que necesites.  </p>
      <p>Dentro de nuestra web, dispones de un área personal desde la que podrás gestionar de manera sencilla la configuración de tu préstamo.</p>
      <p>Una de las cuestiones que más preocupa a los usuarios que deciden recurrir al sistema de préstamos con aval de coche es la flexibilidad en los pagos. Con Ibancar no solo podrás elegir la fecha en la que deseas abonar las cuotas, sino que además contarás con un plazo de hasta cinco días de cortesía para poder efectuar el pago de manera cómoda y tranquila.</p>
      <p>También debes saber que, cuando optes por el servicio alternativo a <strong>empeñar coche Madrid</strong>, la cancelación del préstamo se efectuará de manera sencilla en el momento que lo desees, sin largas esperas ni molestos trámites.</p>
      <p>En el contrato tienes una revisión técnica anual de mantenimiento totalmente gratuita, entre otros servicios exclusivos.</p>
      <p>¡Así que no lo dudes! No esperes más y empieza ahora con la solicitud con Ibancar.</p>
      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
        <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>
<!-- fila 1  -->
      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4">
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamo por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../empenar-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito por tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">La mejor solución al empeño de Coches. Préstamos rápidos con tu coche sin dejar de usarlo</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Dinero rápido con tu coche</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      <!--  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
      </div> 
<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito aval coche con Asnef</h3>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h3 class="card-title naranja inline">Crédito con aval</h3>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCocheMalaga',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Empeñar Coche de forma fácil, segura ▷ Madrid | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Obtén hasta 6.000 euros en 24 horas empeñando tu coche en Madrid con Ibancar, realiza todo el proceso 100% online.'},
        {name:'keywords', content:'empeña tu coche Madrid'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-between(xl,xxl) {
  h3 {
    font-size: 1.25rem;
  }
}
</style>